import React, { useState } from 'react';
import { motion } from 'framer-motion';
import StarRatings from 'react-rating-stars-component';

const initialTestimonials = [
  { id: 1, name: 'Deema', comment: 'Great products, really high quality!', rating: 5 },
  { id: 2, name: 'Kunafa', comment: 'Delivery was super fast and I love my new bracelet.', rating: 5 },
  { id: 3, name: 'Ahmed', comment: 'Exceptional customer service.', rating: 5 },
];

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState(initialTestimonials);
  const [newReview, setNewReview] = useState({ name: '', comment: '', rating: 0 });

  const addReview = (event) => {
    event.preventDefault();
    setTestimonials([...testimonials, { ...newReview, id: testimonials.length + 1 }]);
    setNewReview({ name: '', comment: '', rating: 0 });
  };

  const handleRatingChange = (rating) => {
    setNewReview({ ...newReview, rating });
  };

  const handleInputChange = (event) => {
    setNewReview({ ...newReview, [event.target.name]: event.target.value });
  };

  return (
    <motion.section 
      className="testimonials text-center"
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <h2 className="text-3xl font-bold mb-8">What Our Customers Say</h2>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="p-4 bg-white rounded-lg shadow-lg">
            <StarRatings
              count={5}
              value={testimonial.rating}
              size={24}
              edit={false}
              activeColor="#ffd700"
              className="mb-2"
            />
            <p className="text-gray-600 mb-4">{testimonial.comment}</p>
            <footer className="font-semibold">{testimonial.name}</footer>
          </div>
        ))}
      </div>
      <div className="mt-10">
        <h2 className="text-3xl font-bold mb-4">Leave Your Review</h2>
        <form onSubmit={addReview} className="space-y-4 max-w-lg mx-auto">
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={newReview.name}
            onChange={handleInputChange}
            required
            className="input input-bordered w-full"
          />
          <textarea
            name="comment"
            placeholder="Your Review"
            value={newReview.comment}
            onChange={handleInputChange}
            required
            className="textarea textarea-bordered w-full"
          />
          <div className="flex justify-center">
            <StarRatings
              count={5}
              value={newReview.rating}
              onChange={handleRatingChange}
              size={24}
              activeColor="#ffd700"
            />
          </div>
          <button type="submit" className="btn btn-primary w-full">Submit Review</button>
        </form>
      </div>
    </motion.section>
  );
};

export default Testimonials;
