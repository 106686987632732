import React, { useState, useEffect } from 'react';
import { useCart } from './CartContext';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebaseConfig';

const Store = () => {
    const { addToCart } = useCart();
    const [products, setProducts] = useState([]);
    const [cartMessage, setCartMessage] = useState('');
    const [showFullImage, setShowFullImage] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    // Fetch products from Firestore
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productsCollection = collection(db, "products");
                const productSnapshot = await getDocs(productsCollection);
                const productList = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setProducts(productList);
            } catch (error) {
                console.error("Error fetching products: ", error);
            }
        };

        fetchProducts();
    }, []);

    const handleAddToCart = (product) => {
        addToCart(product);
        setCartMessage(`Added "${product.name}" to Cart`);
        setTimeout(() => {
            setCartMessage('');
        }, 3000);
    };

    const openFullImage = (image) => {
        setSelectedImage(image);
        setShowFullImage(true);
    };

    const closeFullImage = () => {
        setShowFullImage(false);
    };

    return (
        <div className="container mx-auto mt-10">
            {products.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {products.map((product) => (
                        <div key={product.id} className="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition-transform transform hover:scale-105 relative">
                            <div className="cursor-pointer" onClick={() => openFullImage(product.image)}>
                                <img src={product.image} alt={product.name} className="w-full h-auto object-cover" style={{ maxHeight: '400px', maxWidth: '100%' }} />
                            </div>
                            <h3 className="text-lg font-semibold mt-2">{product.name}</h3>
                            <p className="text-gray-800">${product.price}</p>
                            <button className="mt-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:ring-blue-300" onClick={() => handleAddToCart(product)}>Add to Cart</button>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="text-center py-10">
                    <h2 className="text-xl font-semibold">Products coming soon!</h2>
                </div>
            )}
            {cartMessage && (
                <div className="text-white text-center mt-4">{cartMessage}</div>
            )}
            <div className="fixed top-4 right-4">
                <Link to="/cart" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">View Cart</Link>
            </div>
            {showFullImage && (
                <div className="fixed top-20 left-20 right-20 bottom-20 bg-black bg-opacity-60 flex items-center justify-center">
                    <div>
                        <img src={selectedImage} alt="Full view" className="max-h-full max-w-full" />
                        <button className="absolute top-4 right-4 text-white text-2xl hover:text-red-500 focus:outline-none" onClick={closeFullImage}>&#x2716;</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Store;
