import React, { useState, useEffect } from 'react';
import { useCart } from './CartContext';
import { db } from './firebaseConfig';

const Checkout = () => {
    
    const { cartItems, clearCart } = useCart();
    const [deliveryOption, setDeliveryOption] = useState('pickup');
    const [address, setAddress] = useState({ street: '', city: '', state: '', zip: '', country: '' });
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [total, setTotal] = useState(0);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [orderNumber, setOrderNumber] = useState('');

    const handleDeliveryChange = (e) => {
        setDeliveryOption(e.target.value);
    };

    const handleAddressChange = (field) => (e) => {
        setAddress({ ...address, [field]: e.target.value });
    };

    const handleNameChange = (e) => setName(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePhoneChange = (e) => setPhone(e.target.value);
    const handlePaymentMethodChange = (e) => setPaymentMethod(e.target.value);

    const paymentDetails = {
        zelle: '317-919-1744',
        applePay: '317-919-1744',
        cashApp: '$abmnq',
        venmo: '@abmnq',
    };

    useEffect(() => {
        let newTotal = cartItems.reduce((sum, item) => sum + item.price * item.quantity, 0);
        if (deliveryOption === 'delivery') {
            newTotal += 4;
        }
        setTotal(newTotal);
    }, [cartItems, deliveryOption]);
    

    const generateOrderNumber = async () => {
        const ordersRef = db.collection('orders');
        let lastOrderNumber = '#dj0';
        const querySnapshot = await ordersRef.orderBy("orderNumber", "desc").limit(1).get();
        if (!querySnapshot.empty) {
            lastOrderNumber = querySnapshot.docs[0].data().orderNumber;
        }
        const lastNumber = parseInt(lastOrderNumber.replace('#dj', ''), 10);
        return `#dj${lastNumber + 1}`;
    };

    const updateOrderWithNumber = async (orderId, orderNumber) => {
        await db.collection('orders').doc(orderId).update({ orderNumber });
    };

    const validateAndSubmitOrder = async () => {
        if (!name || !email || !phone || !paymentMethod) {
            alert('Please fill in all required fields');
            return;
        }
    
        try {
            // Generate order number first
            const newOrderNumber = await generateOrderNumber();
    
            // Prepare order data with the generated order number
            const orderData = {
                name,
                email,
                phone,
                address,
                deliveryOption,
                paymentMethod,
                total,
                items: cartItems.map(item => ({ name: item.name, price: item.price })),
                date: new Date().toISOString(),
                orderNumber: newOrderNumber // Assign the generated order number
            };
    
            // Submit the order to Firestore
            await db.collection('orders').add(orderData);
    
            setOrderNumber(newOrderNumber);
            setShowSuccessModal(true);

            // Clear the cart after successful submission
            clearCart();
       
        } catch (error) {
            alert('Error placing order. Please try again.');
            console.error(error);
        }
    };
    return (
        <div className="container mx-auto mt-10 p-6 bg-white shadow-md">
            <h2 className="text-2xl font-bold mb-6">Checkout</h2>
    
            {/* Delivery Option and Address Fields */}
            <div className="mb-4">
                <label className="block mb-2">
                    <input 
                        type="radio" 
                        name="deliveryOption" 
                        value="pickup" 
                        checked={deliveryOption === 'pickup'} 
                        onChange={handleDeliveryChange} 
                    /> Pickup
                </label>
                <label className="block">
                    <input 
                        type="radio" 
                        name="deliveryOption" 
                        value="delivery" 
                        checked={deliveryOption === 'delivery'} 
                        onChange={handleDeliveryChange} 
                    /> Delivery
                </label>
                {deliveryOption === 'delivery' && (
                    <div className="mt-4 grid grid-cols-2 gap-4">
                        <input type="text" value={address.street} onChange={handleAddressChange('street')} placeholder="Street Address" className="p-2 border rounded" />
                        <input type="text" value={address.city} onChange={handleAddressChange('city')} placeholder="City" className="p-2 border rounded" />
                        <input type="text" value={address.state} onChange={handleAddressChange('state')} placeholder="State" className="p-2 border rounded" />
                        <input type="text" value={address.zip} onChange={handleAddressChange('zip')} placeholder="Zip Code" className="p-2 border rounded" />
                        <input type="text" value={address.country} onChange={handleAddressChange('country')} placeholder="Country" className="p-2 border rounded" />
                    </div>
                )}
            </div>
    
            {/* Customer Information Fields */}
            <div className="grid grid-cols-2 gap-4 mb-4">
                <input type="text" value={name} onChange={handleNameChange} placeholder="Full Name" className="p-2 border rounded" />
                <input type="email" value={email} onChange={handleEmailChange} placeholder="Email" className="p-2 border rounded" />
            </div>
            <div className="mb-4">
                <input type="tel" value={phone} onChange={handlePhoneChange} placeholder="Phone Number" className="p-2 border rounded w-full" />
            </div>
    
            {/* Payment Method Selection */}
            <div className="mb-4">
                <label htmlFor="paymentMethod" className="block mb-2">Payment Method</label>
                <select id="paymentMethod" value={paymentMethod} onChange={handlePaymentMethodChange} className="p-2 border rounded w-full">
                    <option value="">Select Payment Method</option>
                    <option value="zelle">Zelle</option>
                    <option value="applePay">Apple Pay</option>
                    <option value="cashApp">CashApp</option>
                    <option value="venmo">Venmo</option>
                </select>
                {paymentMethod && (
                    <p className="mt-2">To complete payment, send to {paymentMethod}: {paymentDetails[paymentMethod]}</p>
                )}
            </div>
    
            {/* Total and Checkout Button */}
            <div className="mt-6">
                <p className="font-bold">Total: ${total}</p>
                <button onClick={validateAndSubmitOrder} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4">
                    Checkout
                </button>
            </div>
    
            {/* Success Modal */}
            {showSuccessModal && (
                <div className="absolute top-0 left-0 right-0 bottom-0 bg-white flex items-center justify-center">
                    <div className="bg-gray-100 p-4 rounded shadow-lg text-center">
                        <p>Your order has been placed successfully!</p>
                        <p>Your order number is {orderNumber}.</p>
                        <p>We will verify your order and send it out soon.</p>
                        <p>For pickup orders, please email us at dj@abmnq.com.</p>
                        <button onClick={() => setShowSuccessModal(false)} className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
    
            }

export default Checkout;