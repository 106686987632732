import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="bg-green-900 text-white">
    <div className="container mx-auto py-6 px-4 md:flex md:justify-between md:items-center">
      {/* Logo and description */}
      <div className="mb-4 md:mb-0 md:mr-12">
        <h2 className="text-xl font-bold text-center">DJ-Jewelry</h2>
        <p className="text-sm mt-2 text-center">Quality jewelry for every occasion.</p>
      </div>

      {/* Quick Links */}
      <div className="mb-4 md:mb-0">
        <ul className="flex flex-wrap justify-center md:justify-start gap-4">
          <li><Link to="/" className="hover:underline">Home</Link></li>
          <li><Link to="/store" className="hover:underline">Store</Link></li>
        </ul>
      </div>

      {/* Contact Info */}
      <div className="text-center md:text-left">
        <h3 className="font-semibold">Contact Us</h3>
        <ul className="mt-2">
          <li>Email: info@abmnq.com</li>
        </ul>
      </div>
    </div>

    {/* CopyRight */}
    <div className="text-center py-2 border-t border-gray-300">
      <p className="text-xs">©2024 DJ-Jewelry. All rights reserved.</p>
    </div>
  </footer>
);

export default Footer;
