import React from 'react';
import { motion } from 'framer-motion';
import jo from '../images/jo.PNG';
import da from '../images/da.PNG';

const teamMembers = [
  { name: 'Jomana', role: 'CEO', image: jo },
  { name: 'Dana', role: 'CTO', image: da },
];

const Team = () => (
  <motion.section 
    className="team text-center"
    initial={{ y: -100, opacity: 0 }}
    animate={{ y: 0, opacity: 1 }}
    transition={{ duration: 1 }}
  >
    <h2 className="text-2xl mb-4">Meet Our Team</h2>
    <div className="team-grid grid grid-cols-1 md:grid-cols-2 gap-4">
      {teamMembers.map((member, index) => (
        <div key={index} className="team-member space-y-2 bg-white p-4 rounded-lg shadow-lg">
          {/* Ensure images are the same size. Adjust width and height as needed. */}
          <img src={member.image} alt={member.name} className="mx-auto h-32 w-32 object-cover rounded-full"/>
          <h3 className="text-xl font-bold">{member.name}</h3>
          <p className="text-gray-600">{member.role}</p>
        </div>
      ))}
    </div>
  </motion.section>
);

export default Team;
