import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import Store from './Store';
import Cart from './Cart';
import Footer from './Footer';
import Admin from './Admin';
import Checkout from './Checkout'; 
import LoginG from './LoginG';
import HomePage from './HomePage';
import { CartProvider } from './CartContext';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebaseConfig';

function App() {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <CartProvider>
      <Router>
        {/* Updated layout to use flex and occupy minimum screen height */}
        <div className="flex flex-col min-h-screen bg-gray-100">
          <nav className="bg-blue-800 p-4 text-white">
            <ul className="flex justify-center space-x-4">
              <li><Link to="/" className="hover:text-blue-300">Home</Link></li>
              <li><Link to="/store" className="hover:text-blue-300">Store</Link></li>
              {user && (
                <li><Link to="/admin" className="hover:text-blue-300">Admin</Link></li>
              )}
            </ul>
          </nav>
          {/* Content container */}
          <div className="flex-grow">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/store" element={<Store />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/admin" element={user ? <Admin /> : <Navigate to="/loginG" />} />
              <Route path="/loginG" element={!user ? <LoginG /> : <Navigate to="/admin" />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </CartProvider>
  );
}

export default App;
