import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import Footer from './Footer';
import Testimonials from './components/Testimonials';
import Team from './components/Team';

const HomePage = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const productsCollection = collection(db, "products");
      const snapshot = await getDocs(productsCollection);
      const productsList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setFeaturedProducts(productsList.slice(0, 4)); // Display only the first 4 products as featured
    };

    fetchProducts();
  }, []);

  return (
    <div className="container mx-auto mt-10 px-4">
      <h1 className="text-4xl font-bold text-center mb-10 text-gray-800">Welcome to DJ Jewelry</h1>
      <div className="text-center mb-20">
        <p className="mb-5 text-gray-700">Discover our wide range of products, carefully curated for your satisfaction.</p>
        <Link to="/store" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Shop Now
        </Link>
      </div>

      {/* Featured Products Section */}
      <div className="mb-20">
        <h2 className="text-2xl font-semibold text-center mb-6 text-gray-800">Featured Products</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
          {featuredProducts.map((product) => (
            <div key={product.id} className="rounded overflow-hidden shadow-lg">
              <img className="w-full h-48 object-cover" src={product.image} alt={product.name} />
              <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2 text-gray-800">{product.name}</div>
                <p className="text-gray-700 text-base">${product.price}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="text-center mb-10">
        <Link to="/store" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          See More Items
        </Link>
      </div>

      {/* Team and Testimonials Sections with better spacing */}
      <div className="my-20">
        <Team />
      </div>

      <div className="mb-20">
        <Testimonials />
      </div>

     
    </div>
  );
};

export default HomePage;
