import React, { createContext, useContext, useState, useEffect } from 'react';


const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState(() => {
        // Check for items in local storage
        const savedCartItems = localStorage.getItem('cartItems');
        return savedCartItems ? JSON.parse(savedCartItems) : [];
    });

    useEffect(() => {
        // Save cartItems to local storage when they change
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const clearCart = () => {
        setCartItems([]); // Clear the state
        localStorage.removeItem('cartItems'); // Clear local storage
    };
    

    const addToCart = (item) => {
        const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);
    
        if (existingItem) {
            setCartItems((currentItems) =>
                currentItems.map((cartItem) =>
                    cartItem.id === item.id
                        ? { ...cartItem, quantity: cartItem.quantity + 1 } // Assuming you add one item at a time
                        : cartItem
                )
            );
        } else {
            setCartItems((currentItems) => [...currentItems, { ...item, quantity: 1 }]); // Ensure quantity is set to 1 for new items
        }
    };

    const removeFromCart = (itemId) => {
        setCartItems(cartItems.filter(item => item.id !== itemId));
    };

    const updateQuantity = (itemId, newQuantity) => {
        setCartItems(cartItems.map(item => 
            item.id === itemId ? { ...item, quantity: newQuantity } : item
        ));
    };

    const getTotalItems = () => {
        return cartItems.reduce((total, item) => total + item.quantity, 0);
    };

    const getTotalPrice = () => {
        // Debug log to check items
        console.log("Cart Items:", cartItems);

        return cartItems.reduce((total, item) => {
            if (typeof item.price === "number" && typeof item.quantity === "number") {
                return total + (item.price * item.quantity);
            } else {
                // Log problematic item
                console.error("Invalid item in cart:", item);
                return total;
            }
        }, 0);
    };

    return (
        <CartContext.Provider
            value={{
                cartItems,
                addToCart,
                removeFromCart,
                updateQuantity,
                getTotalItems,
                getTotalPrice,
                clearCart, // Add clearCart here
            }}
        >
            {children}
        </CartContext.Provider>
    );
};