import React from 'react';
import { useCart } from './CartContext';

const CartItem = ({ item }) => {
    const { updateQuantity, removeFromCart } = useCart();

    const handleQuantityChange = (newQuantity) => {
        updateQuantity(item.id, newQuantity);
    };

    return (
        <div className="flex items-center hover:bg-gray-100 -mx-8 px-6 py-5">
            <div className="flex w-2/5">
                <div className="w-20">
                    {/* Updated to use item.image */}
                    <img src={item.image} alt={item.name} className="h-24 object-cover" />
                </div>
                <div className="flex flex-col justify-between ml-4 flex-grow">
                    <span className="font-bold text-sm">{item.name}</span>
                    <button className="text-red-500 hover:text-red-700 text-xs" onClick={() => removeFromCart(item.id)}>
                        Remove
                    </button>
                </div>
            </div>
            <div className="flex justify-center w-1/5">
                <button className="font-semibold hover:text-gray-600 text-gray-600 text-sm" onClick={() => handleQuantityChange(Math.max(0, item.quantity - 1))}>
                    -
                </button>
                <span className="text-black mx-2">{item.quantity}</span>
                <button className="font-semibold hover:text-gray-600 text-gray-600 text-sm" onClick={() => handleQuantityChange(item.quantity + 1)}>
                    +
                </button>
            </div>
            <span className="text-center w-1/5 font-semibold text-sm">${item.price}</span>
            <span className="text-center w-1/5 font-semibold text-sm">${item.price * item.quantity}</span>
        </div>
    );
};

export default CartItem;
