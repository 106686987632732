import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/auth';


// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC0OZoBtU_FqyEfbH_hakMLZTsbsWwg5kg",
    authDomain: "djjewelry-923f0.firebaseapp.com",
    projectId: "djjewelry-923f0",
    storageBucket: "djjewelry-923f0.appspot.com",
    messagingSenderId: "465530179386",
    appId: "1:465530179386:web:c0c2a7e8f1add5eeb60a03",
    measurementId: "G-WPHS95DMWF"
};

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app(); // if already initialized, use that one
}
const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const mediaBucketRef = storage.ref('media');

export { auth, db, storage, mediaBucketRef };