import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { collection, addDoc, getDocs, updateDoc, doc,deleteDoc  } from 'firebase/firestore';

import { auth, db, storage } from './firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const Admin = () => {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [image, setImage] = useState(null); // Now holds the file object
  const [orders, setOrders] = useState([]);
  const [products, setProducts] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentEditProduct, setCurrentEditProduct] = useState(null);
  


  useEffect(() => {
    const fetchProducts = async () => {
      const productsCollectionRef = collection(db, "products");
      const productSnapshot = await getDocs(productsCollectionRef);
      const productList = productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productList);
    };
  
    const fetchOrders = async () => {
      const ordersCollectionRef = collection(db, "orders");
      const ordersSnapshot = await getDocs(ordersCollectionRef);
      const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setOrders(ordersList);
    };
  
    fetchProducts();
    fetchOrders();
  }, []);
  

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!user) return <div>No access. Please log in.</div>;

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };


  const openEditModal = (product) => {
    setCurrentEditProduct(product);
    setIsEditModalOpen(true);
  };
  
  const togglePaidStatus = async (orderId, isPaid) => {
    try {
      await updateDoc(doc(db, 'orders', orderId), { paid: !isPaid });
      // Update the state directly to reflect the change
      setOrders(orders.map(order => {
        if (order.id === orderId) {
          return { ...order, paid: !isPaid };
        } else {
          return order;
        }
      }));
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };
  
  const deleteProduct = async (productId) => {
    try {
      await deleteDoc(doc(db, 'products', productId));
      setProducts(products.filter(product => product.id !== productId));
      alert('Product deleted successfully!');
    } catch (error) {
      console.error("Error deleting product: ", error);
      alert('Error deleting product.');
    }
  };

  // Edit product example (adapt this as needed for your use case)
  const editProduct = async (e) => {
    e.preventDefault();
    try {
      const productId = currentEditProduct.id;
      const updatedProduct = {
        name, // These values should come from the form inputs within your modal
        price: parseFloat(price),
        // Optionally handle image update here as well
      };
      await updateDoc(doc(db, 'products', productId), updatedProduct);
      // Update local state to reflect changes
      setProducts(products.map(product => product.id === productId ? { ...product, ...updatedProduct } : product));
      alert('Product updated successfully!');
      setIsEditModalOpen(false); // Close the modal
    } catch (error) {
      console.error("Error updating product: ", error);
      alert('Error updating product.');
    }
  };
  

  const deleteOrder = async (orderId) => {
    try {
      await deleteDoc(doc(db, 'orders', orderId));
      setOrders(orders.filter(order => order.id !== orderId));
      alert('Order deleted successfully!');
    } catch (error) {
      console.error("Error deleting order: ", error);
      alert('Error deleting order.');
    }
  };

  const editOrder = async (orderId, updatedOrder) => {
    try {
      await updateDoc(doc(db, 'orders', orderId), updatedOrder);
      setOrders(orders.map(order => {
        if (order.id === orderId) {
          return { ...order, ...updatedOrder };
        } else {
          return order;
        }
      }));
      alert('Order updated successfully!');
    } catch (error) {
      console.error("Error updating order: ", error);
      alert('Error updating order.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!image) {
      alert("Please upload an image for the product");
      return;
    }
    
    const imageRef = ref(storage, `products/${image.name}_${Date.now()}`);
    const uploadTask = uploadBytesResumable(imageRef, image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Optional: Handle upload progress
      },
      (error) => {
        // Handle unsuccessful uploads
        console.error("Upload failed: ", error);
        alert("Error uploading image.");
      },
      () => {
        // Handle successful uploads on complete
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          try {
            await addDoc(collection(db, "products"), {
              name,
              price: parseFloat(price),
              image: downloadURL,
            });
            setName('');
            setPrice('');
            setImage(null); // Clear the image state
            alert('Product added successfully!');
          } catch (error) {
            console.error("Error adding document: ", error);
            alert('Error adding product.');
          }
        });
      }
    );
  };
  

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Add New Product</h2>
      <form onSubmit={handleSubmit} className="mb-8">
        <input 
          type="text" 
          value={name} 
          onChange={(e) => setName(e.target.value)} 
          placeholder="Product Name" 
          required 
          className="input input-bordered w-full mb-4" 
        />
        <input 
          type="number" 
          value={price} 
          onChange={(e) => setPrice(e.target.value)} 
          placeholder="Price" 
          required 
          className="input input-bordered w-full mb-4" 
        />
        <input 
          type="file" 
          onChange={handleImageChange} 
          required 
          className="input input-bordered w-full mb-4" 
        />
        <button type="submit" className="btn btn-primary">Add Product</button>
      </form>


  {/* Manage Products Section */}
  <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Manage Products</h2>
        {products.map(product => (
          <div key={product.id} className="bg-white p-4 rounded mb-2 flex justify-between items-center">
            <div>
              <p>Product Name: {product.name}</p>
              <p>Price: ${product.price}</p>
            </div>
            <div className="flex">
              {/* You might want to create a modal or another page for editing products to handle product details update */}
              <button onClick={() => openEditModal(product)}  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded mr-2">
                Edit
              </button>
              <button onClick={() => deleteProduct(product.id)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded">
                Delete
              </button>
            </div>
          </div>
        ))}
{isEditModalOpen && (
  <div className="modal">
    <form onSubmit={editProduct}>
      <input 
        type="text" 
        value={name} 
        onChange={(e) => setName(e.target.value)} 
        placeholder="Product Name" 
        required 
      />
      <input 
        type="number" 
        value={price} 
        onChange={(e) => setPrice(e.target.value)} 
        placeholder="Price" 
        required 
      />
      <input 
          type="file" 
          onChange={handleImageChange} 
          required 
          className="input input-bordered w-full mb-4" 
        />
      {/* Include a file input for image if you're handling image updates */}
      <button type="submit">Update Product</button>
    </form>
    <button onClick={() => setIsEditModalOpen(false)}>Cancel</button>
  </div>
)}

      <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Manage Orders</h2>
      {orders.map(order => (
        <div key={order.id} className="bg-white p-4 rounded mb-2">
          <p>Order Number: {order.orderNumber}</p>
          <p>Customer: {order.name}</p>
          <p>Total: ${order.total}</p>
          <p>Status: <span style={{ color: order.paid ? 'green' : 'red' }}>
            {order.paid ? 'Paid' : 'Unpaid'}
          </span></p>
          <div className="flex">
            <button onClick={() => togglePaidStatus(order.id, order.paid)} className={`bg-${order.paid ? 'red' : 'green'}-500 hover:bg-${order.paid ? 'red' : 'green'}-700 bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded mr-2`}>
              Mark as {order.paid ? 'Unpaid' : 'Paid'}
            </button>
            <button onClick={() => deleteOrder(order.id)} className="bg-green-500  text-white font-bold py-1 px-2 rounded mr-2">
              Delete
            </button>
            {/* Example edit button */}
            <button onClick={() => editOrder(order.id, { name: 'Updated Name', total: 99.99 })} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded">
              Edit
            </button>
          </div>
        </div>
      ))}
    </div>
  

          
    </div>
    </div>
  );
};

export default Admin;
