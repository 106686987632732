import React, { useState, useEffect } from 'react';
import { useCart } from './CartContext';
import CartItem from './CartItem';
import { Link, useNavigate } from 'react-router-dom';

const Cart = () => {
    const { cartItems, removeFromCart, getTotalPrice } = useCart();
    const [deliveryOption] = useState('pickup');
    const [address, setAddress] = useState('');
    const shippingCharge = 4;
    const navigate = useNavigate();

    const total = getTotalPrice() + (deliveryOption === 'delivery' ? shippingCharge : 0);


    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    };

    const handleCheckout = () => {
        navigate('/checkout');
    };

    return (
        <div className="container mx-auto mt-10 px-4 sm:px-0">
            <div className="flex flex-col sm:flex-row shadow-md my-10">
                <div className="w-full bg-white px-4 py-10 sm:px-10 sm:w-3/4">
                    <div className="flex flex-col sm:flex-row justify-between border-b pb-8">
                        <h1 className="font-semibold text-xl sm:text-2xl">Shopping Cart</h1>
                        <h2 className="font-semibold text-xl sm:text-2xl">{cartItems.reduce((total, item) => total + item.quantity, 0)} Items</h2>
                    </div>
                    <div className="grid grid-cols-4 text-base uppercase text-black mb-5">
    <span className="font-semibold">Product Details</span>
    <span className="font-semibold text-center">Quantity</span>
    <span className="font-semibold text-center">Price</span>
    <span className="font-semibold text-center">Total</span>
</div>
                    {cartItems.map((item) => (
                        <CartItem key={item.id} item={item} removeFromCart={removeFromCart} />
                    ))}
                    <Link to="/" className="flex font-semibold text-black text-indigo-600 text-base mt-10">
                        Continue Shopping
                    </Link>
                </div>

                <div id="summary" className="w-full px-4 py-10 sm:px-8 sm:py-10 sm:w-1/4">
                    <h1 className="font-semibold text-xl sm:text-2xl text-black border-b pb-8">Order Summary</h1>
                    <div className="flex justify-between mt-10 mb-5">
                        <span className="font-semibold text-base uppercase text-black">Items {cartItems.reduce((total, item) => total + item.quantity, 0)}</span>
                        <span className="font-semibold text-base text-black">${total}</span>
                    </div>
                    <div>
                        <p className="text-base text-red-900">
                            <span className="font-semibold">Pickup - Free</span> <br />
                            <span className="font-semibold">Delivery +${shippingCharge}</span>
                        </p>
                    </div>
                    {deliveryOption === 'delivery' && (
                        <div className="py-2">
                            <label htmlFor="address" className="font-semibold inline-block mb-3 text-base uppercase text-black">
                                Delivery Address
                            </label>
                            <input
                                type="text"
                                id="address"
                                name="address"
                                placeholder="Enter your address"
                                className="p-2 text-base w-full"
                                onChange={handleAddressChange}
                            />
                        </div>
                    )}
                    <div className="border-t mt-8">
                        <div className="flex font-semibold justify-between py-6 text-base uppercase text-black">
                            <span>Total cost</span>
                            <span>${total}</span>
                        </div>
                        <button
                            className="bg-indigo-500 font-semibold hover:bg-indigo-600 py-3 text-base text-white uppercase w-full"
                            onClick={handleCheckout}
                        >
                            Checkout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Cart;