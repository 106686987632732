import React from 'react';
import { auth } from './firebaseConfig';
import firebase from 'firebase/compat/app';

function LoginG() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider)
      .then((result) => {
        // Google Sign-In was successful, user's info can be retrieved from result.user
        console.log(result.user);
      })
      .catch((error) => {
        // Handle errors here
        console.log(error);
      });
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-3xl font-semibold mb-6">Sign in with Google</h1>
      <button
        onClick={signInWithGoogle}
        className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition duration-300 transform hover:scale-105"
      >
        Sign in with Google
      </button>
    </div>
  );
}

export default LoginG;